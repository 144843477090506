import React, { useContext, useEffect, useState } from 'react'
// @ts-ignore
import { Countdown as AlliumCountdown, StackView } from '@telus-uds/components-web'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import { getLabelValues } from './utils/getLabelValues'

export type CountdownProps = {
  targetDateAndTime: string | Date
  label?: boolean
  noDivider?: boolean
  largeText?: boolean
  feature?: boolean
  inverse?: boolean
  alignment?: string
  textTransformEn?: string
  textTransformFr?: string
}

const Countdown = ({
  targetDateAndTime,
  label = false,
  noDivider = false,
  largeText = false,
  feature = false,
  inverse = false,
  alignment = 'center',
  textTransformEn,
  textTransformFr,
}: CountdownProps) => {
  const [hasMounted, setHasMounted] = useState<boolean>(false)
  const formattedDateAndTime = new Date(targetDateAndTime)

  // Detects language
  const { locale } = useContext(SiteBuilderContext)
  const language = locale?.language

  // Gets values for labels to override UDS default dictionary values
  const labelValues = getLabelValues(textTransformEn, textTransformFr, language)

  // Delays rendering AlliumCountdown until after Countdown has mounted on client side to prevent hydration issue
  useEffect(() => {
    setHasMounted(true)
  }, [])

  // noDivider is only valid when label is true. Updates to false if noDivider is true but label is false to prevent error.
  const noDividerValue: boolean = label && noDivider

  return (
    <StackView tokens={{ alignItems: alignment }}>
      {hasMounted && (
        <AlliumCountdown
          data-testid="countdown-testid"
          copy={labelValues}
          targetTime={formattedDateAndTime}
          variant={{
            label: label,
            noDivider: noDividerValue,
            large: largeText,
            feature: feature,
            inverse: inverse,
          }}
        />
      )}
    </StackView>
  )
}

export default Countdown
