import { defaultDictionary, capitalizedDictionary, lowercaseDictionary, uppercaseDictionary } from './dictionary'

const dictionaryMap = {
  Uppercase: uppercaseDictionary,
  Lowercase: lowercaseDictionary,
  Capitalize: capitalizedDictionary,
}

export const getLabelValues = (textTransformEn: string, textTransformFr: string, language: string) => {
  const textTransform = language === 'en' ? textTransformEn : textTransformFr
  const dictionary = dictionaryMap[textTransform]

  return dictionary ? dictionary[language] : defaultDictionary[language]
}
