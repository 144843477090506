import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { ICountdownFields } from 'contentful-types'
import { CountdownProps } from './Countdown'
import Countdown from './Countdown'

const contentfulAlignmentMapper: Record<ICountdownFields['alignment'], string> = {
  Center: 'center',
  Left: 'flex-start',
  Right: 'flex-end',
}

const ContentfulCountdown = withContentful<ICountdownFields, CountdownProps>(Countdown, {
  alignment: (props) => contentfulAlignmentMapper[props.fields.alignment],
})

export default ContentfulCountdown
