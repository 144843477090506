export const capitalizedDictionary = {
  en: {
    days: 'Days',
    day: 'Day',
    hours: 'Hours',
    hour: 'Hour',
    minutes: 'Minutes',
    minute: 'Minute',
    seconds: 'Seconds',
    second: 'Second',
  },
  fr: {
    days: 'Jours',
    day: 'Jour',
    hours: 'Heures',
    hour: 'Heure',
    minutes: 'Minutes',
    minute: 'Minute',
    seconds: 'Secondes',
    second: 'Seconde',
  },
}

export const lowercaseDictionary = {
  en: {
    days: 'days',
    day: 'day',
    hours: 'hours',
    hour: 'hour',
    minutes: 'minutes',
    minute: 'minute',
    seconds: 'seconds',
    second: 'second',
  },
  fr: {
    days: 'jours',
    day: 'jour',
    hours: 'heures',
    hour: 'heure',
    minutes: 'minutes',
    minute: 'minute',
    seconds: 'secondes',
    second: 'seconde',
  },
}

export const uppercaseDictionary = {
  en: {
    days: 'DAYS',
    day: 'DAY',
    hours: 'HOURS',
    hour: 'HOUR',
    minutes: 'MINUTES',
    minute: 'MINUTE',
    seconds: 'SECONDS',
    second: 'SECOND',
  },
  fr: {
    days: 'JOURS',
    day: 'JOUR',
    hours: 'HEURES',
    hour: 'HEURE',
    minutes: 'MINUTES',
    minute: 'MINUTE',
    seconds: 'SECONDES',
    second: 'SECONDE',
  },
}

export const defaultDictionary = {
  en: capitalizedDictionary.en,
  fr: lowercaseDictionary.fr,
}
